import getAxiosInstance from './axios';
import { URLS } from './urls';

const getTips = async (orderId, flow, axios = getAxiosInstance()) => {
  try {
    const { data } = await axios.get(URLS.getTips(orderId, flow));
    return data;
  } catch (error) {
    //logger.error('[services][getTips]', error.message);
    throw error;
  }
};

export default getTips;
