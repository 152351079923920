export const NOT_APPLICABLE_TIPS = 'notApplicable';
export const TEXT_TITLE_KEY = 'title';
export const TEXT_SUBTITLE_KEY = 'subTitle';
export const TEXT_OTHER_TITLE_KEY = 'otherTitle';
export const TEXT_MESSAGE_KEY = 'message';
export const TEXT_CONGRATS_MESSAGE = 'congratsMessage';
export const TEXT_CONGRATS_TITLE = 'congratsTitle';

export const ANDROID = 'android';
export const IOS = 'ios';
export const HUAWEI = 'huawei';
export const WEB = 'web';
export const MWEB = 'mweb';

export const TIP_CLICKED = 'tip_amount_clicked';
export const TIP_LOADED = 'tip_loaded';
export const TIP_SUBMITTED = 'tip_submitted';

export const DEFAULT_TEXTS = {
  [TEXT_TITLE_KEY]: 'Dejale una propina a Carlos',
  [TEXT_SUBTITLE_KEY]: 'El 100% irá directo a su bolsillo',
  [TEXT_MESSAGE_KEY]: 'Un esfuerzo merece ser reconocido',
  [TEXT_CONGRATS_TITLE]: '¡Gracias por tu propina!',
  [TEXT_CONGRATS_MESSAGE]: 'Carlos la recibirá junto a su próximo pago.',
};
