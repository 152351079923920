import { useEffect, useState } from 'react';

export const useTracking = (trackingPromise) => {
  const [tracker, setTracker] = useState(null);
  const [trackerLoading, setTrackerLoading] = useState(true);
  useEffect(() => {
    const resolveTracking = async () => {
      try {
        const trackerObject = await trackingPromise;
        setTracker(trackerObject);
      } finally {
        setTrackerLoading(false);
      }
    };
    resolveTracking();
  }, []);
  return { tracker, trackerLoading };
};
