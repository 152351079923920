import React from 'react';

const CellphoneError = () => (
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M71.5 118.5H86.93C89.201 118.505 91.3824 117.614 93 116.02C93.7971 115.238 94.4298 114.304 94.8608 113.273C95.2919 112.243 95.5126 111.137 95.51 110.02V16.96C95.5115 15.8432 95.2902 14.7374 94.8593 13.7072C94.4283 12.677 93.7962 11.743 93 10.96C91.3791 9.3733 89.1982 8.48943 86.93 8.49997H71.5V118.5Z"
      fill="#EAE3EB"
      stroke="#CAB8CD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M80 118.5H41C39.8877 118.501 38.7861 118.283 37.7579 117.859C36.7298 117.435 35.7953 116.812 35.0079 116.026C34.2205 115.241 33.5955 114.308 33.1686 113.281C32.7417 112.253 32.5213 111.152 32.52 110.04V16.9599C32.5213 15.8476 32.7417 14.7465 33.1686 13.7194C33.5955 12.6923 34.2205 11.7593 35.0079 10.9737C35.7953 10.1881 36.7298 9.5653 37.7579 9.14086C38.7861 8.71641 39.8877 8.49863 41 8.49994H80C81.1123 8.49863 82.214 8.71641 83.2421 9.14086C84.2702 9.5653 85.2047 10.1881 85.9921 10.9737C86.7796 11.7593 87.4046 12.6923 87.8315 13.7194C88.2583 14.7465 88.4787 15.8476 88.48 16.9599V110.04C88.4787 111.152 88.2583 112.253 87.8315 113.281C87.4046 114.308 86.7796 115.241 85.9921 116.026C85.2047 116.812 84.2702 117.435 83.2421 117.859C82.214 118.283 81.1123 118.501 80 118.5Z"
      fill="#F4F0F5"
      stroke="#CAB8CD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M84.44 103H36.56C35.8854 103.003 35.2373 102.738 34.7575 102.264C34.2776 101.79 34.0053 101.145 34 100.47V23.54C34.0013 23.2051 34.0686 22.8738 34.1979 22.5649C34.3273 22.2561 34.5162 21.9757 34.7539 21.7398C34.9917 21.504 35.2735 21.3172 35.5834 21.1903C35.8933 21.0634 36.2251 20.9987 36.56 21H84.44C84.7749 20.9987 85.1067 21.0634 85.4166 21.1903C85.7265 21.3172 86.0083 21.504 86.2461 21.7398C86.4838 21.9757 86.6727 22.2561 86.8021 22.5649C86.9314 22.8738 86.9987 23.2051 87 23.54V100.47C86.9947 101.145 86.7223 101.79 86.2425 102.264C85.7627 102.738 85.1146 103.003 84.44 103V103Z"
      fill="white"
    />
    <path d="M84 21H37" stroke="#CAB8CD" strokeLinecap="round" />
    <path d="M84 103.36H37" stroke="#CAB8CD" strokeLinecap="round" />
    <path
      d="M57.79 13.11H67.21C67.5416 13.11 67.8595 13.2417 68.0939 13.4761C68.3283 13.7105 68.46 14.0285 68.46 14.36C68.46 14.6915 68.3283 15.0094 68.0939 15.2439C67.8595 15.4783 67.5416 15.61 67.21 15.61H57.79C57.4585 15.61 57.1406 15.4783 56.9062 15.2439C56.6717 15.0094 56.54 14.6915 56.54 14.36C56.54 14.0285 56.6717 13.7105 56.9062 13.4761C57.1406 13.2417 57.4585 13.11 57.79 13.11V13.11Z"
      fill="#CAB8CD"
    />
    <path
      d="M51 15.61C50.7528 15.61 50.5111 15.5367 50.3055 15.3993C50.1 15.262 49.9398 15.0667 49.8452 14.8383C49.7505 14.6099 49.7258 14.3586 49.774 14.1161C49.8223 13.8736 49.9413 13.6509 50.1161 13.4761C50.2909 13.3013 50.5137 13.1822 50.7561 13.134C50.9986 13.0858 51.2499 13.1105 51.4784 13.2051C51.7068 13.2997 51.902 13.46 52.0393 13.6655C52.1767 13.8711 52.25 14.1128 52.25 14.36C52.25 14.6915 52.1183 15.0094 51.8839 15.2439C51.6495 15.4783 51.3315 15.61 51 15.61V15.61Z"
      fill="#CAB8CD"
    />
    <path
      d="M91.15 28.17V24.76C91.15 24.5345 91.2396 24.3183 91.399 24.1589C91.5584 23.9995 91.7746 23.91 92 23.91C92.2255 23.91 92.4417 23.9995 92.6011 24.1589C92.7605 24.3183 92.85 24.5345 92.85 24.76V28.17C92.85 28.3954 92.7605 28.6116 92.6011 28.771C92.4417 28.9304 92.2255 29.02 92 29.02C91.7746 29.02 91.5584 28.9304 91.399 28.771C91.2396 28.6116 91.15 28.3954 91.15 28.17V28.17Z"
      fill="#CAB8CD"
    />
    <path
      d="M91.15 47.75V40.94C91.15 40.7145 91.2396 40.4983 91.399 40.3389C91.5584 40.1795 91.7746 40.09 92 40.09C92.2255 40.09 92.4417 40.1795 92.6011 40.3389C92.7605 40.4983 92.85 40.7145 92.85 40.94V47.75C92.85 47.9754 92.7605 48.1916 92.6011 48.351C92.4417 48.5104 92.2255 48.6 92 48.6C91.7746 48.6 91.5584 48.5104 91.399 48.351C91.2396 48.1916 91.15 47.9754 91.15 47.75Z"
      fill="#CAB8CD"
    />
    <path
      d="M91.15 59.6699V52.86C91.15 52.6345 91.2396 52.4183 91.399 52.2589C91.5584 52.0995 91.7746 52.0099 92 52.0099C92.2255 52.0099 92.4417 52.0995 92.6011 52.2589C92.7605 52.4183 92.85 52.6345 92.85 52.86V59.6699C92.85 59.8954 92.7605 60.1116 92.6011 60.271C92.4417 60.4304 92.2255 60.52 92 60.52C91.7746 60.52 91.5584 60.4304 91.399 60.271C91.2396 60.1116 91.15 59.8954 91.15 59.6699Z"
      fill="#CAB8CD"
    />
    <path
      d="M60.62 114.25C59.9278 114.25 59.2511 114.045 58.6755 113.66C58.0999 113.276 57.6513 112.729 57.3864 112.089C57.1215 111.45 57.0522 110.746 57.1872 110.067C57.3223 109.388 57.6556 108.765 58.1451 108.275C58.6346 107.786 59.2582 107.452 59.9372 107.317C60.6161 107.182 61.3198 107.252 61.9594 107.516C62.5989 107.781 63.1456 108.23 63.5301 108.806C63.9147 109.381 64.12 110.058 64.12 110.75C64.12 111.21 64.0295 111.665 63.8536 112.089C63.6777 112.514 63.4199 112.9 63.0949 113.225C62.7699 113.55 62.384 113.808 61.9594 113.984C61.5347 114.159 61.0796 114.25 60.62 114.25V114.25Z"
      stroke="#CAB8CD"
      strokeLinecap="round"
    />
    <path
      d="M64.584 50.015H48.424C48.3669 50.0066 48.3086 50.0116 48.2538 50.0298C48.199 50.0479 48.1492 50.0786 48.1084 50.1194C48.0676 50.1602 48.0369 50.21 48.0187 50.2648C48.0006 50.3196 47.9955 50.3779 48.004 50.435V52.725C48.004 55.285 49.814 56.675 53.074 56.675H64.654C65.2427 56.675 65.8074 56.9089 66.2237 57.3252C66.6401 57.7416 66.874 58.3062 66.874 58.895C66.874 59.4838 66.6401 60.0484 66.2237 60.4648C65.8074 60.8811 65.2427 61.115 64.654 61.115H51.654C51.5646 61.1169 51.4776 61.1442 51.4032 61.1938C51.3288 61.2434 51.2701 61.3132 51.234 61.395L48.214 73.515C48.1953 73.6363 48.2201 73.7602 48.284 73.865C48.3795 73.953 48.5041 74.0028 48.634 74.005H52.734C53.2976 74 53.8433 73.8062 54.2838 73.4545C54.7244 73.1028 55.0342 72.6136 55.164 72.065L56.344 67.695H64.594C65.7805 67.7349 66.963 67.5357 68.071 67.1091C69.1789 66.6826 70.1897 66.0375 71.0432 65.2122C71.8967 64.3869 72.5754 63.3983 73.039 62.3053C73.5025 61.2123 73.7414 60.0372 73.7414 58.85C73.7414 57.6628 73.5025 56.4877 73.039 55.3947C72.5754 54.3016 71.8967 53.3131 71.0432 52.4878C70.1897 51.6625 69.1789 51.0174 68.071 50.5909C66.963 50.1643 65.7805 49.9651 64.594 50.005L64.584 50.015Z"
      fill="#CAB8CD"
    />
    <path
      opacity="0.11"
      d="M86.5 56C93.4036 56 99 50.4036 99 43.5C99 36.5964 93.4036 31 86.5 31C79.5964 31 74 36.5964 74 43.5C74 50.4036 79.5964 56 86.5 56Z"
      fill="#40004D"
    />
    <path
      d="M89.5 53C96.4036 53 102 47.4036 102 40.5C102 33.5964 96.4036 28 89.5 28C82.5964 28 77 33.5964 77 40.5C77 47.4036 82.5964 53 89.5 53Z"
      fill="#B40443"
      stroke="#100423"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M85.08 36.0801L93.92 44.9201"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M85.08 44.9201L93.92 36.0801"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CellphoneError;
