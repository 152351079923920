import { TIP_CLICKED, TIP_LOADED, TIP_SUBMITTED } from './constants';

export const addResourceHint = ({ href, crossOrigin = false }) => {
  const linkTag = document.createElement('link');
  linkTag.href = href;
  linkTag.crossOrigin = crossOrigin;
  linkTag.rel = 'prefetch';
  linkTag.as = 'script';
  document.head.appendChild(linkTag);
};

export const redirectTo = (url, { useSingleSpaNavigate = true } = {}) => {
  if (useSingleSpaNavigate) {
    window.singleSpaNavigate(url);
  } else {
    window.location = url;
  }
};

export const redirectToCloseUrl = (device, countryCode) => {
  const closeURL = `/feedback/close?origin=tips&device=${device}&c=${countryCode}`;
  redirectTo(closeURL);
};

export const trackTipClicked = (tracker, orderId, tipAmount, origin) => {
  tracker?.track(TIP_CLICKED, {
    tipAction: 'add',
    tipCustom: 'standardTip',
    orderId,
    tipAmount,
    origin,
  });
};

export const trackTipSubmitted = (tracker, orderId, tipAmount, origin) => {
  tracker?.track(TIP_SUBMITTED, { orderId, tipAmount, origin });
};

export const trackTipLoaded = (tracker, orderId, origin) => {
  tracker?.track(TIP_LOADED, { orderId, origin });
};
