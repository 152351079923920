import pkg from '../../package.json';

const API_PREFFIX = `tips/api/${pkg.name}`;

export const URLS = {
  getTips: (orderId, flow) =>
    `${API_PREFFIX}/getTips?flow=${encodeURIComponent(flow)}&orderId=${encodeURIComponent(
      orderId,
    )}`,
  sendTipToCharge: () => `${API_PREFFIX}/sendTip`,
};
