import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import GlobalStyles from '@pedidosya/web-fenix/theme/GlobalStyles';

import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';
import Error from '@pedidosya/web-fenix/pages/Error';
import { useCloseFunction, useRedirectToCallbackFunction, DataContext } from '../../hooks';

const ErrorBoundary = ({ title, primaryLabel }) => {
  const { redirectUrl } = useContext(DataContext);
  const { closeFn } = useCloseFunction();
  const { redirectToCallback } = useRedirectToCallbackFunction();
  const onCloseFn = redirectUrl ? redirectToCallback : closeFn;
  return (
    <ThemeProvider>
      <>
        <FenixFonts />
        <GlobalStyles />
        <Error
          error={500}
          message={title}
          primaryActionClick={onCloseFn}
          primaryActionLabel={primaryLabel}
        />
      </>
    </ThemeProvider>
  );
};

ErrorBoundary.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  primaryLabel: PropTypes.string,
  onPrimaryAction: PropTypes.func,
};

ErrorBoundary.defaultProps = {
  title: null,
  primaryLabel: 'Volver',
};

export default ErrorBoundary;
