import React, { useEffect, useState } from 'react';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { useTips, useCloseFunction } from '../../hooks';
import { NOT_APPLICABLE_TIPS } from '../../utils/constants';
import Congrats from '../../commonComponents/Congrats';
import TipsContent from '../../commonComponents/TipsContent';
import TipsError from '../../commonComponents/TipsError';

const OrderStatusRoot = () => {
  const { closeFn } = useCloseFunction();
  const [showCongrats, setShowCongrats] = useState(false);
  const {
    congratsTitle,
    congratsMessage,
    title,
    message,
    subtitle,
    tipsData,
    selectedTip,
    setSelectedTip,
    showError,
    loading,
    sendTip,
  } = useTips();

  useEffect(() => {
    if (tipsData?.status === NOT_APPLICABLE_TIPS) {
      closeFn();
    } else if (tipsData?.tipDetails?.amount > 0) {
      setShowCongrats(true);
    }
  }, [tipsData, closeFn]);

  const handleConfirmTip = async () => {
    sendTip();
    setShowCongrats(true);
  };

  if (loading) return <PeyaLoader position="center" />;

  if (showError) {
    return <TipsError onClose={closeFn} />;
  }

  if (showCongrats)
    return (
      <Congrats
        title={congratsTitle}
        message={congratsMessage}
        onConfirm={() => closeFn({ refresh: true })}
      />
    );

  return (
    <TipsContent
      message={message}
      title={title}
      subtitle={subtitle}
      tips={tipsData.tips}
      selectedTip={selectedTip}
      setSelectedTip={setSelectedTip}
      onConfirmTip={handleConfirmTip}
      onCancelTip={closeFn}
      onClose={closeFn}
    />
  );
};

export default OrderStatusRoot;
