import { useContext } from 'react';
import { DataContext } from './context';
import { IOS, ANDROID, HUAWEI } from '../utils/constants';

const CLOSE_EVENT = 'CLOSE';

const OS_CLOSE_FUNCTIONS = {
  [IOS]: (payload = {}) => iOSCloseFunction(payload),
  [ANDROID]: (payload = {}) => androidCloseFunction(payload),
  [HUAWEI]: (payload = {}) => androidCloseFunction(payload),
};

const iOSCloseFunction = (payload = {}) => {
  window.webkit.messageHandlers.ListenEventsInterface.postMessage({
    event: CLOSE_EVENT,
    payload,
  });
};

const androidCloseFunction = (payload = {}) => {
  window.TipsWebEvents.sendEvents(
    JSON.stringify({
      event: CLOSE_EVENT,
      payload,
    }),
  );
};

export const useCloseFunction = () => {
  const { device } = useContext(DataContext);
  return { closeFn: OS_CLOSE_FUNCTIONS[device] };
};
