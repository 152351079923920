import styled from 'styled-components';

const TipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 170px;
`;

export default TipContainer;
