import React from 'react';
import PropTypes from 'prop-types';
import Button from '@pedidosya/web-fenix/atoms/Button';
import { Header } from '.';
import CellphoneError from '../assets/CellphoneError';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyleOverrides = createGlobalStyle`
body {
  background-color: white;
  font-family: 'Segma';
}
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ErrorTitle = styled.h1`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin: 32px 16px 0 16px;
  padding: 0 32px;
  text-align: center;
`;

const ErrorMessage = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin: 16px 16px 40px 16px;
  padding: 0 32px;
  text-align: center;
`;

const TipsError = ({ onClose }) => (
  <>
    <GlobalStyleOverrides />
    <Header onClose={onClose} boxShadow />
    <ErrorContainer>
      <CellphoneError />
      <ErrorTitle>No podemos agregar tu propina ahora</ErrorTitle>
      <ErrorMessage>¡Lo sentimos! Inténtalo de nuevo más tarde.</ErrorMessage>
      <Button label="Volver al inicio" size="large" onClick={onClose} />
    </ErrorContainer>
  </>
);

TipsError.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TipsError;
