import { useContext } from 'react';
import { DataContext } from './context';
import { redirectTo } from '../utils';

export const useRedirectToCallbackFunction = ({ useSingleSpaNavigate = true } = {}) => {
  const { redirectUrl } = useContext(DataContext);
  return {
    redirectToCallback: () => redirectTo(redirectUrl, { useSingleSpaNavigate }),
  };
};
