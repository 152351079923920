/* eslint-disable react/prop-types */
import React, { useEffect, useContext } from 'react';
import * as Sentry from '@sentry/react';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';
import GlobalStyles from '@pedidosya/web-fenix/theme/GlobalStyles';
import { DataContext, useCloseFunction, useQueryParams, useTracking } from './hooks';
import initSentry from './utils/sentry';
import TipsError from './commonComponents/TipsError';

const SentryProvider = ({ children }) => {
  const { environment } = useContext(DataContext);

  useEffect(() => {
    const sentryConfig = {
      dsn: 'https://22a120382788193798aff2c7bbe87358@o4504046939799552.ingest.us.sentry.io/4507228013527040',
      isEnabled: environment && environment !== 'local',
      environment,
    };

    async function initializeSentry() {
      try {
        await initSentry(sentryConfig);
      } catch (error) {
        console.error('Error initializing sentry hub', error.message);
      }
    }
    initializeSentry();
  }, [environment]);

  return children;
};

export default function RootProvider({ context, trackerPromise, children }) {
  const queryParams = useQueryParams();
  const { tracker, trackerLoading } = useTracking(trackerPromise);
  const { orderId, redirectUrl, device, c: countryCode, flow } = queryParams.params;
  const deviceOS = device || context?.deviceInfo?.os || 'N/A';
  const platform = context?.deviceInfo?.platform || null;
  const { closeFn } = useCloseFunction();

  if (!queryParams.paramsSetted || trackerLoading) return null;

  return (
    <ThemeProvider>
      <DataContext.Provider
        value={{
          orderId,
          flow: flow,
          redirectUrl: redirectUrl && decodeURIComponent(redirectUrl),
          device: deviceOS,
          environment: context?.environment,
          countryCode,
          tracker,
          platform,
        }}
      >
        <SentryProvider>
          <Sentry.ErrorBoundary fallback={<TipsError onClose={closeFn} />}>
            <FenixFonts />
            <GlobalStyles />
            {children}
          </Sentry.ErrorBoundary>
        </SentryProvider>
      </DataContext.Provider>
    </ThemeProvider>
  );
}
