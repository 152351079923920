import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import { Header } from '.';
import bgNeutral from '../assets/images/background_white.png';
import bgSuccess from '../assets/images/background_green.png';
import riderImg from '../assets/images/icon_rider_container_feedback.png';
import riderSuccessImg from '../assets/images/icon_rider_container.png';

const GlobalStyleOverrides = createGlobalStyle`
body {
  background-color: white;
  font-family: 'Segma';
}
`;

const RiderImage = styled.img`
  width: 84px;
  margin-bottom: 40px;
`;

const Layout = styled.div`
  position: relative;
`;

const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  top: 175px;
  text-align: center;
`;

const TipsLayout = ({ type = 'neutral', onClose, children }) => (
  <Layout>
    <GlobalStyleOverrides />
    <Header onClose={onClose} boxShadow />
    <img
      src={type === 'neutral' ? bgNeutral : bgSuccess}
      height={250}
      width={'100%'}
      alt="background"
    />
    <Content>
      <RiderImage src={type === 'neutral' ? riderImg : riderSuccessImg} />
      {children}
    </Content>
  </Layout>
);

TipsLayout.propTypes = {
  type: PropTypes.oneOf(['neutral', 'success']),
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default TipsLayout;
