import React from 'react';
import PropTypes from 'prop-types';
import Button from '@pedidosya/web-fenix/atoms/Button';
import TipsLayout from './TipsLayout';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 12px;
  margin-top: 0px;
`;

const SubTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin: 0 16px 40px 16px;
`;

const TipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Congrats = ({ title, message, onConfirm }) => (
  <TipsLayout type="success" onClose={onConfirm}>
    <TipContainer>
      <Title>{title}</Title>
      <SubTitle>{message}</SubTitle>
      <ButtonContainer>
        <Button label="Confirmar" size="large" onClick={onConfirm} />
      </ButtonContainer>
    </TipContainer>
  </TipsLayout>
);

Congrats.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default Congrats;
