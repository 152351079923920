import React, { useContext } from 'react';
import { DataContext } from './hooks';
import { ErrorBoundary } from './commonComponents';
import { flowMainComponent } from './utils/flow';

export default function Root() {
  const { orderId, flow } = useContext(DataContext);

  if (!orderId) {
    return <ErrorBoundary />;
  }

  return (
    <>
      {typeof flowMainComponent[flow] === 'function' ? (
        flowMainComponent[flow]()
      ) : (
        <ErrorBoundary />
      )}
    </>
  );
}
