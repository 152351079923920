import React from 'react';
import OrderStatusTips from '../flows/OrderStatus';
import RiderReviewTips from '../flows/RiderReview';

const RIDER_REVIEW = 'rider_review';
const ORDER_STATUS = 'order_status';
const OS_CLOSING_PUSH = 'os_closing_push';

export const flowMainComponent = {
  [RIDER_REVIEW]: () => <RiderReviewTips />,
  [ORDER_STATUS]: () => <OrderStatusTips />,
  [OS_CLOSING_PUSH]: () => <OrderStatusTips />,
};
