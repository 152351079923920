import styled from 'styled-components';

const TipTitle = styled.h1`
  margin: 4px 16px 0px 16px;
  padding: 0 76px;
  line-height: 24px;
  font-size: 18px;
  font-weight: 600;
`;

export default TipTitle;
