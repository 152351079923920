import React, { useContext, useEffect, useState } from 'react';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';
import { useTips, DataContext, useRedirectToCallbackFunction } from '../../hooks';
import { addResourceHint, redirectToCloseUrl } from '../../utils';
import { NOT_APPLICABLE_TIPS, WEB } from '../../utils/constants';
import Congrats from '../../commonComponents/Congrats';
import TipsError from '../../commonComponents/TipsError';
import TipsContent from '../../commonComponents/TipsContent';

const RootRiderReviewTips = () => {
  const { device, countryCode } = useContext(DataContext);
  const [checkedOrder, setCheckedOrder] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false);
  const { redirectToCallback } = useRedirectToCallbackFunction();
  const {
    tipsData,
    selectedTip,
    setSelectedTip,
    sendTip,
    loading,
    showError,
    title,
    subtitle,
    message,
    congratsTitle,
    congratsMessage,
  } = useTips({
    onErrorCallback: redirectToCallback,
  });

  useEffect(() => {
    /** prefetch feedback */
    try {
      addResourceHint({ href: System.resolve('@microsite/feedback'), crossOrigin: true });
    } catch (err) {
      // TODO: Log to sentry
    }
  }, []);

  useEffect(() => {
    if (loading) return;
    if (isTipsDisabled()) {
      redirectToCallback();
    } else {
      setCheckedOrder(true);
    }
  }, [tipsData, loading]);

  const handleConfirmTip = async () => {
    await sendTip();
    setShowCongrats(true);
  };

  const handleClose = () => redirectToCloseUrl(device, countryCode);

  const isTipsDisabled = () =>
    tipsData?.status === NOT_APPLICABLE_TIPS ||
    tipsData?.tipDetails?.amount > 0 ||
    device.toLowerCase() === WEB;

  if (loading || !checkedOrder) return <PeyaLoader position="center" />;

  if (showError) return <TipsError onClose={handleClose} />;

  if (showCongrats)
    return (
      <Congrats title={congratsTitle} message={congratsMessage} onConfirm={redirectToCallback} />
    );

  return (
    <TipsContent
      message={message}
      title={title}
      subtitle={subtitle}
      tips={tipsData.tips}
      selectedTip={selectedTip}
      setSelectedTip={setSelectedTip}
      onConfirmTip={handleConfirmTip}
      onCancelTip={redirectToCallback}
      onClose={handleClose}
    />
  );
};

export default RootRiderReviewTips;
