import styled from 'styled-components';

const TipSubtitle = styled.p`
  line-height: 20px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export default TipSubtitle;
