import { useEffect, useState } from 'react';

//need to use useEffect to be sure that window would be defined due to ssr
export const useQueryParams = () => {
  const [params, setParams] = useState({});
  const [paramsSetted, setParamsSetted] = useState(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setParams(Object.fromEntries(queryParams));
    setParamsSetted(true);
  }, []);

  return { params, paramsSetted };
};
