/* eslint-disable react/display-name */
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import singleSpaReact from 'single-spa-react';
import pkg from '../package.json';
import RootProvider from './root.provider';
import Root from './root.component';
import { ErrorBoundary } from './commonComponents';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  renderType: 'hydrate',
  loadRootComponent: async () => {
    try {
      const { data: context } = await axios.get(`tips/api/${pkg.name}/env`);
      return (props) => (
        <RootProvider {...props} context={context}>
          <Root />
        </RootProvider>
      );
    } catch (error) {
      return () => (
        <RootProvider>
          <ErrorBoundary error={error} />
        </RootProvider>
      );
    }
  },
  errorBoundary(error) {
    return <ErrorBoundary error={error} />;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
