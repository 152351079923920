import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@pedidosya/web-fenix/atoms/Chip';
import Check from '@pedidosya/web-fenix/icons/Check';
import { TipsContainer, TipContainer } from './styled-components';

const TipsScale = ({ selectTipCallback, selectedTip, tipsList, hideZero }) => {
  const tips = hideZero ? tipsList?.filter((tip) => tip.value !== 0) : tipsList;

  return (
    <TipsContainer>
      {tips?.map((tip, index) => (
        <TipContainer
          key={index}
          data-testid={tip.value === selectedTip ? 'selectedTip' : 'tip'}
          tip={tip}
        >
          <Chip
            id={tip.value}
            label={tip.displayValue}
            onClick={() => selectTipCallback && selectTipCallback(tip.value)}
            state={tip.value === selectedTip ? 'pressed' : 'enabled'}
            anatomy="left-icon"
            icon={tip.value === selectedTip && <Check />}
          />
        </TipContainer>
      ))}
    </TipsContainer>
  );
};

TipsScale.propTypes = {
  selectTipCallback: PropTypes.func.isRequired,
  tipsList: PropTypes.array.isRequired,
  selectedTip: PropTypes.number,
  swiper: PropTypes.bool,
  hideZero: PropTypes.bool,
};

TipsScale.defaultProps = {
  swiper: false,
  hideZero: false,
  selectedTip: null,
};

export default TipsScale;
