import styled from 'styled-components';

const Title = styled.div`
  margin-left: 20px;
  font-family: 'Segma';
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
`;

export default Title;
