import styled from 'styled-components';

const TipMessage = styled.p`
  margin: 0px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
`;

export default TipMessage;
