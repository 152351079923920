/* eslint-disable react/prop-types */
import React from 'react';
import TipsLayout from '../TipsLayout';
import Button from '@pedidosya/web-fenix/atoms/Button';
import {
  ButtonsContainer,
  TipSubtitle,
  TertiaryButton,
  TipMessage,
  TipTitle,
} from './styled-components';
import FenixTipsScale from '../FenixTipsScale';

const TipsContent = ({
  message,
  title,
  tips,
  selectedTip,
  setSelectedTip,
  subtitle,
  onConfirmTip,
  onCancelTip,
  onClose,
}) => {
  return (
    <TipsLayout onClose={onClose}>
      <TipMessage>{message}</TipMessage>
      <TipTitle>{title}</TipTitle>
      <FenixTipsScale
        tipsList={tips}
        selectedTip={selectedTip}
        selectTipCallback={setSelectedTip}
        hideZero
      />
      <TipSubtitle>{subtitle}</TipSubtitle>
      <ButtonsContainer>
        <Button
          label="Confirmar"
          size="large"
          state={selectedTip ? 'enabled' : 'disabled'}
          onClick={onConfirmTip}
        />
        <TertiaryButton>
          <Button label="Ahora no" size="large" hierarchy="tertiary" onClick={onCancelTip} />
        </TertiaryButton>
      </ButtonsContainer>
    </TipsLayout>
  );
};

export default TipsContent;
