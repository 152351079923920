import styled from 'styled-components';

const TitleContainer = styled.div`
  position: absolute;
  display: flex;
  padding: 25px 0px 0px 20px;
  align-items: center;
  z-index: 999;
  padding-bottom: 12px;
`;

export default TitleContainer;
