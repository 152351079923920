import getAxiosInstance from './axios';
import { URLS } from './urls';

const sendTipToCharge = async (amount, orderId, configurationId, axios = getAxiosInstance()) => {
  const data = {
    configurationId,
    amount,
    orderId,
  };
  try {
    const response = await axios.post(URLS.sendTipToCharge(), data);
    return response;
  } catch (error) {
    //logger.error('[services][getTips]', error.message);
    throw error;
  }
};

export default sendTipToCharge;
