import React from 'react';
import PropTypes from 'prop-types';
import Close from '@pedidosya/web-fenix/icons/Close';
import { Title, TitleContainer } from './styled-components';

const Header = ({ title, onClose }) => (
  <TitleContainer data-testid="title-container">
    {onClose && <Close size="medium" onClick={() => onClose()} />}
    <Title>{title}</Title>
  </TitleContainer>
);

Header.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
};

Header.deafultProps = {
  onClose: null,
  title: null,
};

export default Header;
