import { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import services from '../services';
import { trackTipClicked, trackTipLoaded, trackTipSubmitted } from '../utils';
import { DataContext } from './context';
import {
  TEXT_TITLE_KEY,
  TEXT_SUBTITLE_KEY,
  DEFAULT_TEXTS,
  TEXT_MESSAGE_KEY,
  TEXT_CONGRATS_TITLE,
  TEXT_CONGRATS_MESSAGE,
  MWEB,
  WEB,
} from '../utils/constants';
import TipsOnWebError from '../exceptions/TipsOnWebError';

const getTextValues = (tipsData) => (key) => {
  return tipsData?.texts?.find((text) => text.key === key)?.value || DEFAULT_TEXTS[key];
};

export const useTips = ({ onErrorCallback = null, track = true } = {}) => {
  const [tipsData, setTipsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [selectedTip, setSelectedTip] = useState(0);
  const { orderId, flow, tracker, platform } = useContext(DataContext);
  const getWording = getTextValues(tipsData);

  useEffect(() => {
    /**
     * Obtiene las tips dada la orden.
     * ⚠️ Si la plataforma es web/mweb, redirecciona a través de `onErrorCallback`.
     */
    const getTipsScale = async () => {
      try {
        if (platform === MWEB || platform === WEB) {
          throw new TipsOnWebError();
        }

        const tipsData = await services.getTips(orderId, flow);
        const defaultTip = tipsData?.tips?.find((tip) => tip.isDefault);
        setSelectedTip(defaultTip?.value);
        setTipsData(tipsData);
        if (track) {
          trackTipLoaded(tracker, orderId, flow);
        }
        setLoading(false);
      } catch (error) {
        if (!(error instanceof TipsOnWebError)) {
          Sentry.captureException(error);
        }

        if (onErrorCallback) {
          onErrorCallback(error);
        } else {
          setLoading(false);
          setShowError(true);
        }
      }
    };
    getTipsScale();
  }, []);

  const selectTip = (amount) => {
    setSelectedTip(amount);
    if (track) {
      trackTipClicked(tracker, orderId, amount, flow);
    }
  };

  const sendTip = async () => {
    setLoading(true);
    try {
      if (selectedTip > 0) {
        await services.sendTipToCharge(selectedTip, orderId, tipsData.configurationId);
        trackTipSubmitted(tracker, orderId, selectedTip, flow);
      }
    } catch (error) {
      Sentry.captureException(error);
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    tipsData,
    selectedTip,
    setSelectedTip: selectTip,
    loading,
    showError,
    title: getWording(TEXT_TITLE_KEY),
    subtitle: getWording(TEXT_SUBTITLE_KEY),
    message: getWording(TEXT_MESSAGE_KEY),
    congratsTitle: getWording(TEXT_CONGRATS_TITLE),
    congratsMessage: getWording(TEXT_CONGRATS_MESSAGE),
    sendTip,
  };
};
